import { createSlice } from '@reduxjs/toolkit';
import { IFollowUpAppointment } from '../interfaces/followUpAppointment';

type followUpAppointmentStateType = {
  appointments: IFollowUpAppointment[];
  isAddAppointment: boolean;
  isEditAppointment: boolean;
};

const initialState: followUpAppointmentStateType = {
  appointments: [],
  isAddAppointment: false,
  isEditAppointment: false,
};

const followUpAppointmentSlice = createSlice({
  name: 'followUpAppointment',
  initialState,
  reducers: {
    updateAppointments: (state, { payload }) => {
      state.appointments = payload;
    },
    openAddAppointmentForm: (state) => {
      state.isAddAppointment = true;
    },
    openEditAppointmentForm: (state) => {
      state.isEditAppointment = true;
    },
    closeAppointmentForm: (state) => {
      state.isAddAppointment = false;
      state.isEditAppointment = false;
    },
    clearState: () => {
      return initialState;
    },
  },
});

export const {
  updateAppointments,
  openAddAppointmentForm,
  openEditAppointmentForm,
  closeAppointmentForm,
  clearState,
} = followUpAppointmentSlice.actions;
export default followUpAppointmentSlice.reducer;
