import { createSlice } from '@reduxjs/toolkit';
import { ITab } from '../interfaces/tab';

type TabStateType = {
  tabs: ITab[];
  nextUrl: string;
};

const initialState: TabStateType = {
  tabs: [
    {
      name: 'patient',
      title: '',
      date: '',
      url: '/patients/',
      active: true,
    },
  ],
  nextUrl: '',
};

const tabSlice = createSlice({
  name: 'tabs',
  initialState,
  reducers: {
    updatePatientTab: (state, { payload }) => {
      const previousTabs = state.tabs;
      if (payload.active) {
        previousTabs.forEach((tab) => {
          if (tab.active) {
            tab.active = false;
          }
        });
      }
      previousTabs[0] = payload;
      state.tabs = previousTabs;
    },
    pushTab: (state, { payload }) => {
      const previousTabs = state.tabs;
      const duplicate = previousTabs.filter((tab) => tab.name === payload.name);
      const index = previousTabs.findIndex((tab) => tab.name === payload.name);
      previousTabs.forEach((tab) => {
        if (tab.active) {
          tab.active = false;
        }
      });
      if (duplicate.length) {
        previousTabs[index] = payload;
      } else {
        previousTabs.push(payload);
      }
      state.tabs = previousTabs;
      let nextTabIndex = index + 1;
      if (nextTabIndex >= previousTabs.length) {
        nextTabIndex = index - 1;
      }
      state.nextUrl = previousTabs[nextTabIndex].url;
    },
    activateTab: (state, { payload }) => {
      const previousTabs = state.tabs;
      previousTabs.forEach((tab) => {
        if (tab.active) {
          tab.active = false;
        }
      });
      const index = previousTabs.findIndex((tab) => tab.name === payload);
      previousTabs[index].active = true;
      state.tabs = previousTabs;
    },
    popTab: (state) => {
      const previousTabs = state.tabs;
      previousTabs.pop();
      state.tabs = previousTabs;
    },
    removeTab: (state, { payload }) => {
      const previousTabs = state.tabs;
      const tabToRemove = previousTabs.filter((tab) => tab.name === payload)[0];
      if (tabToRemove && tabToRemove.active) {
        const index = previousTabs.findIndex((tab) => tab.name === payload);
        let nextTabIndex = index + 1;
        if (nextTabIndex >= previousTabs.length) {
          nextTabIndex = index - 1;
        }
        previousTabs[nextTabIndex].active = true;
      }
      const newTabs = previousTabs.filter((tab) => tab.name !== payload);
      state.tabs = newTabs;
    },
    clearTabsState: () => {
      return initialState;
    },
  },
});

export const {
  pushTab,
  activateTab,
  popTab,
  removeTab,
  clearTabsState,
  updatePatientTab,
} = tabSlice.actions;
export default tabSlice.reducer;
