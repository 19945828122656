import { createSlice } from '@reduxjs/toolkit';
import { useSelector, TypedUseSelectorHook } from 'react-redux';
import { AppState } from 'src/common/store/index';
import { IPatientMembership } from '../interfaces/patientInsurance';
import { FlagPatientResp } from '../interfaces/flagPatient';

export interface PatientSliceState {
  patientData: any;
  ehrId: string;
  isEditPatient: boolean;
  patientDetails: {
    showDetails: boolean;
  };
  checkPatient: {
    showCheck: boolean;
  };
  addPatient: {
    showAdd: boolean;
  };
  patientMembership: IPatientMembership[] | [];
  isFlagged: boolean;
  patientFlagObj: FlagPatientResp | null;
  showFlagDialogBox: boolean;
  country: string;
}

const initialState: PatientSliceState = {
  patientData: null,
  ehrId: '',
  isEditPatient: false,
  patientDetails: {
    showDetails: false,
  },
  checkPatient: {
    showCheck: false,
  },
  addPatient: {
    showAdd: false,
  },
  patientMembership: [],
  isFlagged: false,
  patientFlagObj: null,
  showFlagDialogBox: false,
  country: '',
};

const patientSlice = createSlice({
  name: 'patient',
  initialState,
  reducers: {
    updatePatientData: (state, { payload }) => {
      state.patientData = payload;
    },
    setEhrId: (state, { payload }) => {
      state.ehrId = payload;
    },
    openPatientDetails: (state) => {
      state.patientDetails.showDetails = true;
    },
    closePatientDetails: (state) => {
      state.patientDetails.showDetails = false;
    },
    openCheckPatientForm: (state) => {
      state.checkPatient.showCheck = true;
    },
    closeCheckPatientForm: (state) => {
      state.checkPatient.showCheck = false;
    },
    openIsEditPatientForm: (state) => {
      state.isEditPatient = true;
    },
    closeIsEditPatientForm: (state) => {
      state.isEditPatient = false;
    },
    openAddPatientForm: (state) => {
      state.addPatient.showAdd = true;
    },
    updatePatientMemberShip: (state, { payload }) => {
      state.patientMembership = payload;
    },
    closeAddPatientForm: (state) => {
      state.addPatient.showAdd = false;
    },
    setCountry: (state, { payload }) => {
      state.country = payload;
    },
    toggleFlagged: (state, { payload }) => {
      state.isFlagged = payload;
    },
    setPatientFlagObj: (state, { payload }) => {
      state.patientFlagObj = payload;
    },
    toggleShowFlagDialogBox: (state) => {
      state.showFlagDialogBox = !state.showFlagDialogBox;
    },
    clearPatientState: () => {
      return initialState;
    },
  },
});

export const ehrId = (state: AppState) => state.patient.ehrId;
export const useAppSelector: TypedUseSelectorHook<AppState> = useSelector;

export const {
  updatePatientData,
  openPatientDetails,
  openIsEditPatientForm,
  closeIsEditPatientForm,
  closePatientDetails,
  openCheckPatientForm,
  closeCheckPatientForm,
  openAddPatientForm,
  updatePatientMemberShip,
  closeAddPatientForm,
  clearPatientState,
  setCountry,
  toggleFlagged,
  setPatientFlagObj,
  toggleShowFlagDialogBox,
  setEhrId,
} = patientSlice.actions;
export default patientSlice.reducer;
