import { searchTypes } from '@ambulatory/features/prescription/resource';
import { SearchType } from '@ambulatory/features/prescription/interfaces/prescription';
import { createSlice } from '@reduxjs/toolkit';

type DateRangeFilter = {
  dateRange: {
    from: string;
    to: string;
  };
};

type Filter = {
  search: string;
  service?: string;
  serviceProvider: string;
  diagnosis?: string;
  branch: {
    code: string;
    value: string;
    label: string;
  } | null;
  dateRange: {
    from: string;
    to: string;
  };
};

interface EncounterFilter extends Filter {
  service: string;
  diagnosis: string;
}

interface PrescriptionFilter extends Filter {
  dispenser: string;
  status: string;
  medication: string;
  pageIndex: number;
  searchType: SearchType;
}

interface PrescriptionRefillFilter extends Filter {
  pageIndex: number;
}

type FilterType = {
  encounters: EncounterFilter;
  prescriptions: PrescriptionFilter;
  prescriptionRefills: PrescriptionRefillFilter;
  labtests: Filter;
  admissions: Filter;
  procedures: Filter;
  idoven: DateRangeFilter;
};

type FilterStateType = {
  filter: FilterType;
};

type PayloadType = {
  payload: {
    type: string;
    value: string;
  };
};

type BranchPayloadType = {
  payload: {
    type: string;
    branch: {
      code: string;
      label: string;
      value: string;
    };
  };
};

type PageIndexPayloadType = {
  payload: {
    type: string;
    value: number;
  };
};

type SearchTypePayload = {
  payload: {
    type: string;
    value: SearchType;
  };
};

const initialState: FilterStateType = {
  filter: {
    encounters: {
      search: '',
      service: '',
      serviceProvider: '',
      diagnosis: '',
      branch: null,
      dateRange: {
        from: '',
        to: '',
      },
    },
    prescriptions: {
      search: '',
      searchType: searchTypes[0],
      serviceProvider: '',
      dispenser: '',
      status: '',
      medication: '',
      pageIndex: 0,
      branch: null,
      dateRange: {
        from: '',
        to: '',
      },
    },
    prescriptionRefills: {
      search: '',
      serviceProvider: '',
      branch: null,
      pageIndex: 0,
      dateRange: {
        from: '',
        to: '',
      },
    },
    labtests: {
      search: '',
      serviceProvider: '',
      branch: null,
      dateRange: {
        from: '',
        to: '',
      },
    },
    admissions: {
      search: '',
      serviceProvider: '',
      branch: null,
      dateRange: {
        from: '',
        to: '',
      },
    },
    procedures: {
      search: '',
      serviceProvider: '',
      branch: null,
      dateRange: {
        from: '',
        to: '',
      },
    },
    idoven: {
      dateRange: {
        from: '',
        to: '',
      },
    },
  },
};

const filterSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    setSearchFilter: (state, { payload }: PayloadType) => {
      (state as any).filter[payload.type].search = payload.value;
    },
    setBranchFilter: (state, { payload }: BranchPayloadType) => {
      (state as any).filter[payload.type].branch = payload.branch;
    },
    setSeviceFilter: (state, { payload }: PayloadType) => {
      (state as any).filter[payload.type].service = payload.value;
    },
    setProviderFilter: (state, { payload }: PayloadType) => {
      (state as any).filter[payload.type].serviceProvider = payload.value;
    },
    setDispenserFilter: (state, { payload }: PayloadType) => {
      (state as any).filter[payload.type].dispenser = payload.value;
    },
    setDiagnosisFilter: (state, { payload }: PayloadType) => {
      (state as any).filter[payload.type].diagnosis = payload.value;
    },
    setMedicationFilter: (state, { payload }: PayloadType) => {
      (state as any).filter[payload.type].medication = payload.value;
    },
    setStatusFilter: (state, { payload }: PayloadType) => {
      (state as any).filter[payload.type].status = payload.value;
    },
    setPageIndexFilter: (state, { payload }: PageIndexPayloadType) => {
      (state as any).filter[payload.type].pageIndex = payload.value;
    },
    setSearchTypeFilter: (state, { payload }: SearchTypePayload) => {
      (state as any).filter[payload.type].searchType = payload.value;
    },
    setDateFilter: (state, { payload }) => {
      if (payload.dateRange.placeHolder === 'from') {
        (state as any).filter[payload.type].dateRange['from'] =
          payload.dateRange.isoDate;
      } else {
        (state as any).filter[payload.type].dateRange['to'] =
          payload.dateRange.isoDate;
      }
    },
    removeFromDateFilter: (state, { payload }) => {
      if (payload.value === 'from') {
        (state as any).filter[payload.type].dateRange['from'] = '';
      } else {
        (state as any).filter[payload.type].dateRange['to'] = '';
      }
    },
    clearFilterState: () => {
      return initialState;
    },
  },
});

export const {
  setSearchFilter,
  setBranchFilter,
  setSeviceFilter,
  setProviderFilter,
  setDispenserFilter,
  setDiagnosisFilter,
  setDateFilter,
  removeFromDateFilter,
  setMedicationFilter,
  setPageIndexFilter,
  setStatusFilter,
  setSearchTypeFilter,
  clearFilterState,
} = filterSlice.actions;

export default filterSlice.reducer;
