import { configureStore } from '@reduxjs/toolkit';
import { createWrapper } from 'next-redux-wrapper';

import encounterReducer from '@ambulatory/features/encounter/store/EncounterSlice';
import patientReducer from '@ambulatory/features/patient/store/PatientSlice';
import socialHistoryReducer from '@ambulatory/features/encounter/store/socialHistorySlice';
import allergyReducer from '@ambulatory/features/encounter/store/AllergySlice';
import currentMedicationReducer from '@ambulatory/features/encounter/store/CurrentMedicationSlice';
import vitalSignReducer from '@ambulatory/features/encounter/store/VitalSignSlice';
import fallRiskAssessmentReducer from '@ambulatory/features/encounter/store/FallRiskAssessmentSlice';
import anthropometryReducer from '@ambulatory/features/encounter/store/AnthropometrySlice';
import patientExaminationReducer from '@ambulatory/features/encounter/store/PatientExaminationSlice';
import vaccinationReducer from '@ambulatory/features/vaccination/store/VaccinationSlice';
import patientFlowReducer from '@patientFlow/features/patient-flow/store/PatientFlowSlice';
import patientFlowTicketReducer from '@patientFlow/features/patient-flow/store/PatientFlowTicketSlice';
import clinicsReducer from '@patientFlow/features/patient-flow/store/ClinicsSlice';
import careProviderReducer from '@appointment/features/appointment/store/CareProviderSlice';
import appointmentReducer from '@appointment/features/appointment/store/appointment';
import healthCareServiceReducer from '@appointment/features/appointment/store/HealthCareServiceSlice';
import LifestyleReducer from '@ambulatory/features/encounter/store/lifestyleSlice';
import searchedPatientReducer from '@ambulatory/features/patient/store/SearchedPatientSlice';
import clientReducer from '@appointment/features/appointment/store/ClientSlice';
import calendarReducer from '@appointment/features/appointment/store/CalendarSlice';
import PastMedicalHistoryReducer from '@ambulatory/features/encounter/store/PastMedicalHistorySlice';
import FamilyHistoryReducer from '@ambulatory/features/encounter/store/FamilyHistorySlice';
import PastSurgicalHistoryReducer from '@ambulatory/features/encounter/store/PastSurgicalHistorySlice';
import ObstericHistoryReducer from '@ambulatory/features/encounter/store/ObstericHistorySlice';
import GynaecologySummaryReducer from '@ambulatory/features/encounter/store/GynaecologySummarySlice';
import PregnancySummaryReducer from '@ambulatory/features/encounter/store/PregnancySummary';
import PhysicalExaminationReducer from '@ambulatory/features/encounter/store/PhysicalExamination';
import SystemReviewReducer from '@ambulatory/features/encounter/store/SystemReviewSlice';
import NextOfKinReducer from '@ambulatory/features/patient/store/NextOfKinSlice';
import FormRendererReducer from 'src/common/store/FormRenderer';
import ProcedureReducer from '@ambulatory/features/procedure/store/ProcedureSlice';
import patientInsuranceSlice from '@ambulatory/features/patient/store/PatientInsuranceSlice';
import ImagingReducer from '@ambulatory/features/encounter/store/ImagingTest';
import ReferralReducer from '@ambulatory/features/encounter/store/ReferralsSlice';
import DocumentReducer from 'src/common/store/DocumentSlice';
import CarePlanReducer from '@ambulatory/features/care-plan/store/CarePlanSlice';
import FollowUpAppointmentReducer from '@ambulatory/features/encounter/store/FollowUpAppointmentSlice';
import UserReducer from '@settings/features/users/store/UserSlice';
import RoomReducer from '@patientFlow/features/patient-flow/store/RoomSlice';
import StageReducer from '@patientFlow/features/patient-flow/store/StageSlice';
import labReducer from '@ambulatory/features/labtest/store/LabSlice';
import messageReducer from 'src/common/store/MessageSlice';
import BloodGroupReducer from '@ambulatory/features/patient/store/BloodGroupSlice';
import LabOrderReducer from '@ambulatory/features/labtest/store/LabOrderSlice';
import PrescriptionReducer from '@ambulatory/features/prescription/store/Prescription';
import NutritionReducer from '@ambulatory/features/encounter/store/NutritionAssessmentSlice';
import PainReducer from '@ambulatory/features/encounter/store/PainAssessmentSlice';
import ContraceptiveReducer from '@ambulatory/features/encounter/store/contraceptiveUseSlice';
import PlanReducer from '@ambulatory/features/encounter/store/PlanSlice';
import admissionReducer from '@ambulatory/features/admission/store/AdmissionSlice';
import TabsSlice from '@ambulatory/features/patient/store/TabsSlice';
import DentalExaminationSlice from '@ambulatory/features/encounter/store/DentalExaminationSlice';
import OcularExaminationSlice from '@ambulatory/features/encounter/store/OcularExaminationSlice';
import PastDentalHistoryReducer from '@ambulatory/features/encounter/store/PastDentalHistorySlice';
import PastOcularHistoryReducer from '@ambulatory/features/encounter/store/pastOcularHistorySlice';
import FilterReducer from 'src/common/store/filterSlice';
import MedicationAdministrationReducer from '@ambulatory/features/encounter/store/medicationAdministered';
import FluidReducer from 'src/common/store/FluidSlice';
import VisionSpringReducer from '@ambulatory/features/encounter/store/VisionSpringSlice';
import LogNoteReducer from 'src/common/store/LogNoteSlice';
import MedicalReportReducer from '@ambulatory/features/encounter/store/MedicalReportSlice';

import NewReviewReducer from '@qapp/features/chart-audits/store/newReview';
import ReviewFilterReducer from '@qapp/features/chart-audits/store/reviewFilters';
import ChartReviewReducer from '@qapp/features/chart-audits/store/chartReview';
import ListFiltersReducer from '@qapp/common/store/listFilters';
import ChatAppReducer from '../../chat-app/store/chatappSlice';
import BMGFStudyReducer from '@ambulatory/features/bmgf/store';
import AntenatalCareReducer from '@ambulatory/features/anc/store/AntenatalCareSlice';

import SettingsReducer from '@settings/common/store/settingsSlice';
import FeatureSettingsReducer from '@settings/features/features/store/featureSettingsSlice';

export const store = configureStore({
  reducer: {
    encounter: encounterReducer,
    patient: patientReducer,
    allergy: allergyReducer,
    currentMedication: currentMedicationReducer,
    vitalSign: vitalSignReducer,
    fallRiskAssessment: fallRiskAssessmentReducer,
    anthropometry: anthropometryReducer,
    vaccination: vaccinationReducer,
    patientExamination: patientExaminationReducer,
    patientFlow: patientFlowReducer,
    patientFlowTicket: patientFlowTicketReducer,
    clinics: clinicsReducer,
    careProvider: careProviderReducer,
    healthCareService: healthCareServiceReducer,
    appointment: appointmentReducer,
    searchedPatient: searchedPatientReducer,
    client: clientReducer,
    calendar: calendarReducer,
    pastMedicalHistory: PastMedicalHistoryReducer,
    pastSurgicalHistory: PastSurgicalHistoryReducer,
    familyHistory: FamilyHistoryReducer,
    obstetricHistory: ObstericHistoryReducer,
    gynaecologySummary: GynaecologySummaryReducer,
    pregnancySummary: PregnancySummaryReducer,
    physicalExamination: PhysicalExaminationReducer,
    systemReview: SystemReviewReducer,
    nextofkin: NextOfKinReducer,
    formRenderer: FormRendererReducer,
    procedure: ProcedureReducer,
    patientInsurance: patientInsuranceSlice,
    imagingTest: ImagingReducer,
    referral: ReferralReducer,
    carePlan: CarePlanReducer,
    document: DocumentReducer,
    followUpAppointment: FollowUpAppointmentReducer,
    user: UserReducer,
    room: RoomReducer,
    stage: StageReducer,
    lifestyle: LifestyleReducer,
    lab: labReducer,
    message: messageReducer,
    bloodGroup: BloodGroupReducer,
    labOrder: LabOrderReducer,
    prescription: PrescriptionReducer,
    nutrition: NutritionReducer,
    pain: PainReducer,
    socialHistory: socialHistoryReducer,
    contraceptive: ContraceptiveReducer,
    filter: FilterReducer,
    plan: PlanReducer,
    admission: admissionReducer,
    tabs: TabsSlice,
    dentalExamination: DentalExaminationSlice,
    ocularExamination: OcularExaminationSlice,
    pastDentalHistory: PastDentalHistoryReducer,
    pastOcularHistory: PastOcularHistoryReducer,
    medicationAdministered: MedicationAdministrationReducer,
    fluid: FluidReducer,
    visionSpring: VisionSpringReducer,
    logNote: LogNoteReducer,
    bmgfStudy: BMGFStudyReducer,
    newReview: NewReviewReducer,
    reviewFilter: ReviewFilterReducer,
    listFilters: ListFiltersReducer,
    chartReview: ChartReviewReducer,
    medicalReport: MedicalReportReducer,
    chatApp: ChatAppReducer,
    settings: SettingsReducer,
    antenatalCare: AntenatalCareReducer,
    features: FeatureSettingsReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});

export const makeStore = () => store;

export const wrapper = createWrapper(makeStore);

export type AppDispatch = typeof store.dispatch;
export type AppState = ReturnType<typeof store.getState>;
