import { BloodGroupType } from '../interfaces/bloodGroup';
import { createSlice } from '@reduxjs/toolkit';

type BloodGroupInitialStateType = {
  bloodGroup: BloodGroupType | null;
  isAddBloodType: boolean;
  isEditBloodType: boolean;
  showForm: boolean;
  isError: boolean;
};

const initialState: BloodGroupInitialStateType = {
  bloodGroup: null,
  isAddBloodType: false,
  isEditBloodType: false,
  showForm: false,
  isError: false,
};

const bloodGroupSlice = createSlice({
  name: 'bloodGroup',
  initialState,
  reducers: {
    updateBloodGroup: (state, { payload }) => {
      state.bloodGroup = payload;
    },
    openAddBloodGroupForm: (state) => {
      state.isAddBloodType = true;
      state.isEditBloodType = false;
      state.showForm = true;
      state.isError = false;
    },
    openEditBloodGroupForm: (state) => {
      state.isAddBloodType = false;
      state.isEditBloodType = true;
      state.showForm = true;
      state.isError = false;
    },
    updateBloodGroupError: (state) => {
      state.bloodGroup = null;
      state.isAddBloodType = false;
      state.isEditBloodType = false;
      state.showForm = false;
      state.isError = true;
    },
    closeBloodGroupForm: (state) => {
      state.isAddBloodType = false;
      state.isEditBloodType = false;
      state.showForm = false;
      state.isError = false;
    },
    clearBloodGroupState: () => {
      return initialState;
    },
  },
});

export const {
  updateBloodGroup,
  openAddBloodGroupForm,
  openEditBloodGroupForm,
  updateBloodGroupError,
  closeBloodGroupForm,
  clearBloodGroupState,
} = bloodGroupSlice.actions;
export default bloodGroupSlice.reducer;
