import { createSlice } from '@reduxjs/toolkit';
import { combineYearAndMonth } from 'src/common/utils/dobUtils';

type Filter = {
  search: string;
  branch: {
    code: string;
    value: string;
    label: string;
  } | null;
  dateRange: {
    from: string;
    to: string;
  };
  serviceProvider: string;
};

interface ChartAuditFilter {
  branch: {
    code: string;
    value: string;
    label: string;
  } | null;
  serviceProvider: string;

  dateRange: {
    year: Date | null;
    month: Date | null;
  };

  chartReviewDate: Date | null;
}

interface ChartAuditListFilter {
  branch: {
    code: string;
    value: string;
    label: string;
  } | null;
  serviceProvider: string;
  encounterId: string;
}

type FilterType = {
  episodeCareAssessments: Filter;
  chartAudit: ChartAuditFilter;
  chartAuditList: ChartAuditListFilter;
};

type FilterStateType = {
  filter: FilterType;
};

type PayloadType = {
  payload: {
    type: string;
    value: string;
  };
};

type DatePayloadType = {
  payload: {
    type: string;
    value: Date | null;
  };
};

type BranchPayloadType = {
  payload: {
    type: string;
    branch: {
      code: string;
      label: string;
      value: string;
    } | null;
  };
};

const initialState: FilterStateType = {
  filter: {
    episodeCareAssessments: {
      search: '',
      branch: null,
      dateRange: {
        from: '',
        to: '',
      },
      serviceProvider: '',
    },
    chartAudit: {
      branch: null,
      serviceProvider: '',
      dateRange: {
        year: new Date(),
        month: new Date(),
      },
      chartReviewDate: combineYearAndMonth(new Date(), new Date()),
    },
    chartAuditList: {
      branch: null,
      serviceProvider: '',
      encounterId: '',
    },
  },
};

const listsFilterSlice = createSlice({
  name: 'listFilters',
  initialState,
  reducers: {
    setSearchFilter: (state, { payload }: PayloadType) => {
      (state as any).filter[payload.type].search = payload.value;
    },
    setBranchFilter: (state, { payload }: BranchPayloadType) => {
      (state as any).filter[payload.type].branch = payload.branch;
    },
    setSeviceFilter: (state, { payload }: PayloadType) => {
      (state as any).filter[payload.type].service = payload.value;
    },
    setDateFilter: (state, { payload }) => {
      if (payload.dateRange.placeHolder === 'from') {
        (state as any).filter[payload.type].dateRange['from'] =
          payload.dateRange.isoDate;
      } else {
        (state as any).filter[payload.type].dateRange['to'] =
          payload.dateRange.isoDate;
      }
    },
    removeFromDateFilter: (state, { payload }) => {
      if (payload.value === 'from') {
        (state as any).filter[payload.type].dateRange['from'] = '';
      } else {
        (state as any).filter[payload.type].dateRange['to'] = '';
      }
    },
    setProviderFilter: (state, { payload }: PayloadType) => {
      (state as any).filter[payload.type].serviceProvider = payload.value;
    },

    setChartReviewDateRange: (state, { payload }: DatePayloadType) => {
      (state as any).filter.chartReview.dateRange[payload.type] = payload.value;
    },

    setChartReviewDate: (state, { payload }) => {
      state.filter.chartAudit.chartReviewDate = payload;
    },

    clearFilterState: () => {
      return initialState;
    },
  },
});

export const {
  setSearchFilter,
  setBranchFilter,
  setDateFilter,
  removeFromDateFilter,
  setProviderFilter,
  setChartReviewDateRange,
  setChartReviewDate,
  clearFilterState,
} = listsFilterSlice.actions;

export default listsFilterSlice.reducer;
