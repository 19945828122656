import { createSlice } from '@reduxjs/toolkit';
import { Stage } from '../interfaces';

const allStages: Stage[] = [];
const stage = {} as Stage;
const initialState = {
  allStages,
  stage,
  stageToasterCreate: false,
  stageToasterUpdate: false,
  stageToasterDelete: false,
};

const stageSlice = createSlice({
  name: 'Stage',
  initialState,
  reducers: {
    addAllStages: (state, { payload }) => {
      state.allStages = payload;
    },
    selectedStage: (state, { payload }) => {
      state.stage = payload;
    },
    createStageToaster: (state, { payload }) => {
      state.stageToasterCreate = payload;
    },
    updateStageToaster: (state, { payload }) => {
      state.stageToasterUpdate = payload;
    },
    deleteStageToaster: (state, { payload }) => {
      state.stageToasterDelete = payload;
    },
  },
});
export const {
  addAllStages,
  selectedStage,
  createStageToaster,
  updateStageToaster,
  deleteStageToaster,
} = stageSlice.actions;
export default stageSlice.reducer;
