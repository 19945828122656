import { createSlice } from '@reduxjs/toolkit';
import { useSelector, TypedUseSelectorHook } from 'react-redux';
import { AppState } from 'src/common/store/index';
import { IInsurance } from '../interfaces/patientInsurance';

const initialState = {
  patientInsuranceData: [] as never as IInsurance[],
};

const patientInsuranceSlice = createSlice({
  name: 'patientInsurance',
  initialState,
  reducers: {
    updatePatientInsurance: (state, { payload }) => {
      state.patientInsuranceData = payload;
    },

    clearPatientInsuranceState: () => {
      return initialState;
    },
  },
});

export const ehrId = (state: AppState) => state.patient.ehrId;
export const useAppSelector: TypedUseSelectorHook<AppState> = useSelector;

export const { updatePatientInsurance, clearPatientInsuranceState } =
  patientInsuranceSlice.actions;
export default patientInsuranceSlice.reducer;
