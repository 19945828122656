import { IContraceptive } from '../interfaces/contraceptive';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  contraceptives: [],
  activeCompositionID: '',
  isAddContraceptive: false,
  isEditContraceptive: false,
  showForm: false,
};

const ContraceptiveSlice = createSlice({
  name: 'contraceptive',
  initialState,
  reducers: {
    updateContraceptive: (state, { payload }) => {
      state.contraceptives = payload;
    },
    updateActiveCompositionID: (state, { payload }) => {
      state.activeCompositionID = payload;
    },
    removeContraceptive: (state, { payload }) => {
      state.contraceptives = state.contraceptives.filter(
        (eachContraceptive: IContraceptive) =>
          eachContraceptive.Composition_uid !== payload,
      );
    },
    openAddContraceptiveForm: (state) => {
      state.isAddContraceptive = true;
      state.showForm = true;
    },
    openEditContraceptiveForm: (state) => {
      state.isEditContraceptive = true;
      state.showForm = true;
    },
    closeContraceptiveForm: (state) => {
      (state.showForm = false),
        (state.isAddContraceptive = false),
        (state.isEditContraceptive = false);
    },
    clearState: () => {
      return initialState;
    },
  },
});

export const {
  updateContraceptive,
  openAddContraceptiveForm,
  updateActiveCompositionID,
  openEditContraceptiveForm,
  closeContraceptiveForm,
  removeContraceptive,
  clearState,
} = ContraceptiveSlice.actions;

export default ContraceptiveSlice.reducer;
