import { IPain } from '../interfaces/painAssessment';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  painAssessments: [],
  isAddPainAssessment: false,
  isEditPainAssessment: false,
  showForm: false,
};

const PainAssessmentSlice = createSlice({
  name: 'painAssessment',
  initialState,
  reducers: {
    updatePainAssessment: (state, { payload }) => {
      state.painAssessments = payload;
    },
    removePainAssessment: (state, { payload }) => {
      state.painAssessments = state.painAssessments.filter(
        (eachPainAssessment: IPain) =>
          eachPainAssessment.Composition_uid !== payload,
      );
    },
    openAddPainAssessmentForm: (state) => {
      state.isAddPainAssessment = true;
      state.showForm = true;
    },
    openEditPainAssessmentForm: (state) => {
      state.isEditPainAssessment = true;
      state.showForm = true;
    },
    closePainAssessmentForm: (state) => {
      (state.showForm = false),
        (state.isAddPainAssessment = false),
        (state.isEditPainAssessment = false);
    },
    clearState: () => {
      return initialState;
    },
  },
});

export const {
  updatePainAssessment,
  openAddPainAssessmentForm,
  openEditPainAssessmentForm,
  closePainAssessmentForm,
  removePainAssessment,
  clearState,
} = PainAssessmentSlice.actions;

export default PainAssessmentSlice.reducer;
