import { createSlice } from '@reduxjs/toolkit';
import { IReferral } from '../interfaces/referral';

type ReferralLaterState = {
  referrals: IReferral[];
  isAddReferral: boolean;
  isEditReferral: boolean;
};

const initialState: ReferralLaterState = {
  referrals: [],
  isAddReferral: false,
  isEditReferral: false,
};

const referralSlice = createSlice({
  name: 'referral',
  initialState,
  reducers: {
    updateReferrals: (state, { payload }) => {
      state.referrals = payload;
    },
    openAddReferralForm: (state) => {
      state.isAddReferral = true;
    },
    openEditReferralForm: (state) => {
      state.isEditReferral = true;
    },
    closeReferralForm: (state) => {
      state.isAddReferral = false;
      state.isEditReferral = false;
    },
    clearState: () => {
      return initialState;
    },
  },
});

export const {
  updateReferrals,
  openAddReferralForm,
  openEditReferralForm,
  closeReferralForm,
  clearState,
} = referralSlice.actions;
export default referralSlice.reducer;
