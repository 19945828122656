import { createSlice } from '@reduxjs/toolkit';
import { MultiValue } from 'react-select';

import { CareProvider } from '../interfaces';
import { ProviderOptions } from '../interfaces';

const careProviders: CareProvider[] = [];
const providerOptions: MultiValue<ProviderOptions> = [];
const defaultProvider = {} as ProviderOptions;
const initialState = {
  careProviders: careProviders,
  providerOptions,
  defaultProvider,
};

const careProviderSlice = createSlice({
  name: 'Care Provider',
  initialState,
  reducers: {
    allCareProviders: (state, { payload }) => {
      state.careProviders = payload;
    },
    toggleSelectedProvider: (state, { payload }) => {
      state.careProviders = state.careProviders.map((each) => {
        if (each.id === payload) {
          each.selected = !each.selected;
        }
        return each;
      });
    },

    setProviderOptions: (state, { payload }) => {
      state.providerOptions = payload;
    },
    setDefaultProvider: (state, { payload }) => {
      state.defaultProvider = payload;
    },
  },
});
export const {
  allCareProviders,
  setProviderOptions,
  setDefaultProvider,
  toggleSelectedProvider,
} = careProviderSlice.actions;
export default careProviderSlice.reducer;
