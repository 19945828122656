import { SearchType } from '../interfaces/prescription';

export const reportColumns = [
  {
    header: 'Prescription Number',
    headerClassName: 'prescription-number',
  },
  {
    header: 'Encounter ID',
    headerClassName: 'encounter-id',
  },
  {
    header: 'Patient Name',
    headerClassName: 'name',
  },
  {
    header: 'Branch',
    headerClassName: 'branch',
  },
  {
    header: 'Prescription Date',
    headerClassName: 'date',
  },
  {
    header: 'Prescriber',
    headerClassName: 'prescriber',
  },
  {
    header: 'Dispenser',
    headerClassName: 'dispenser',
  },
  {
    header: 'Status',
    headerClassName: 'status',
  },
  {
    header: 'Medication Name',
    headerClassName: 'med-name',
  },
  {
    header: 'Indication',
    headerClassName: 'indication',
  },
  {
    header: 'Dosage',
    headerClassName: 'dosage',
  },
];

export const searchTypes: SearchType[] = [
  {
    text: 'Search by Name',
    placeholder: 'Patient name',
    key: 1,
  },
  {
    text: 'Search by Prescription Id',
    placeholder: 'Prescription Id',
    key: 2,
  },
];
