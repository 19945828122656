import { ISocialHistory } from '../interfaces/socialHistory';
import { createSlice } from '@reduxjs/toolkit';

export type ISocialHistoryState = {
  socialHistories: ISocialHistory;
  showForm: boolean;
};

const initialState: ISocialHistoryState = {
  socialHistories: {} as ISocialHistory,
  showForm: false,
};

const SocialHistorySlice = createSlice({
  name: 'socialhistory',
  initialState,
  reducers: {
    updateSocialHistory: (state, { payload }) => {
      state.socialHistories = payload;
    },
    openSocialHistoryForm: (state) => {
      state.showForm = true;
    },
    closeSocialHistoryForm: (state) => {
      state.showForm = false;
    },
    clearState: () => {
      return initialState;
    },
  },
});

export const {
  updateSocialHistory,
  openSocialHistoryForm,
  closeSocialHistoryForm,
  clearState,
} = SocialHistorySlice.actions;

export default SocialHistorySlice.reducer;
