import { createSlice } from '@reduxjs/toolkit';

interface Calendar {
  branch_id: number;
  client_id: number;
  client: {
    name: string;
    patient_ref: string;
  };
  code: string;
  comment: string;
  create_date: string;
  create_username: string;
  id: number;
  provider_id: number;
  provider: {
    name: string;
  };
  service_id: number;
  service: {
    duration: number;
    color: string;

    icon: string;

    name: string;
  };
  start_time: string;
  status: string;
}
interface SelectedEvent {
  id?: number;
  start?: Date;
  end?: Date;
  clientId?: number;
  comment?: string;
  appointmentCode?: string;
  patient_ref?: string;
}
export interface RenderedCalendar {
  start: Date;
  end: Date;
  clientId: number;
  serviceColor: string;
}
interface Appointment {
  start: Date | null | undefined;
  comment: string;
  service: string;
}

const calendar: Calendar[] = [];
const displayedCalendar: RenderedCalendar[] = [];
const calendarSchedule: Appointment = {
  start: new Date(),
  comment: '',
  service: '',
};
const initialState = {
  cancelAppointmentToast: false,
  createAppointmentToast: false,
  updateAppointmentToast: false,
  slotInfo: {} as any,
  selectedEvent: {} as SelectedEvent,
  checkInVariables: {} as SelectedEvent, // This is needed as a way of also holding values that becomes 'null' when appointment slider is closed. The values are needed for checking in patient.
  selectedEventService: '',
  selectedEventInitialTime: '',
  appointment: false,
  calendar,
  displayedCalendar,
  calendarSchedule,
};

const calendarSlice = createSlice({
  name: 'Calendar',
  initialState,
  reducers: {
    allAppointments: (state, { payload }) => {
      state.calendar = payload;
    },
    addAppointmentToCalendar: (state, { payload }) => {
      state.calendar = [...state.calendar, payload];
    },
    updateCalendarAppointment: (state, { payload }) => {
      state.calendar = state.calendar.map((eachAppointment) => {
        if (eachAppointment.id === payload.id) {
          eachAppointment = payload;
        }
        return eachAppointment;
      });
    },
    removeAppoinmentFromCalendar: (state, { payload }) => {
      state.calendar = state.calendar.filter((eachAppointment) => {
        return eachAppointment.id !== payload.id;
      });
    },
    toggleCancelAppointmentToast: (state) => {
      state.cancelAppointmentToast = !state.cancelAppointmentToast;
    },
    toggleCreateAppointmentToast: (state) => {
      state.createAppointmentToast = !state.createAppointmentToast;
    },
    toggleUpdateAppointmentToast: (state) => {
      state.updateAppointmentToast = !state.updateAppointmentToast;
    },
    updateSelectedEventService: (state, { payload }) => {
      state.selectedEventService = payload;
    },
    updateSelectedEventInitialTime: (state, { payload }) => {
      state.selectedEventInitialTime = payload;
    },
    addSlotInfo: (state, { payload }) => {
      state.slotInfo = payload;
    },
    clearSlotInfo: (state) => {
      state.slotInfo = {};
    },
    addSelectedEvent: (state, { payload }) => {
      state.selectedEvent = payload;
    },
    clearSelectedEvent: (state) => {
      state.selectedEvent = {};
    },
    toggleAppointment: (state, { payload }) => {
      state.appointment = payload;
    },
    setDisplayedCalendar: (state, { payload }) => {
      state.displayedCalendar = payload;
    },
    setCalendarSchedule: (state, { payload }) => {
      state.calendarSchedule = payload;
    },
    updateCheckInVariables: (state, { payload }) => {
      state.checkInVariables = payload;
    },
  },
});
export const {
  addSlotInfo,
  clearSlotInfo,
  addSelectedEvent,
  clearSelectedEvent,
  toggleAppointment,
  allAppointments,
  addAppointmentToCalendar,
  updateCalendarAppointment,
  removeAppoinmentFromCalendar,
  toggleCancelAppointmentToast,
  toggleCreateAppointmentToast,
  toggleUpdateAppointmentToast,
  updateSelectedEventService,
  updateSelectedEventInitialTime,
  setDisplayedCalendar,
  setCalendarSchedule,
  updateCheckInVariables,
} = calendarSlice.actions;
export default calendarSlice.reducer;
