import { IEncounter } from '@ambulatory/features/encounter/interfaces/encounter';
import { createSlice } from '@reduxjs/toolkit';

type DashboardStateType = {
  isDashboardView: boolean;
  isAssessmentView: boolean;
  isFinalAssessmentView: boolean;
  isResearchAssessmentView: boolean;
  isEvaluationView: boolean;
  activePatientStudy: IEncounter | null;
  studyAssessments: IEncounter[];
  allAssessments: IEncounter[];
  error: string | null;
};

const initialState: DashboardStateType = {
  isDashboardView: true,
  isAssessmentView: false,
  activePatientStudy: null,
  isFinalAssessmentView: false,
  isResearchAssessmentView: false,
  isEvaluationView: false,
  studyAssessments: [],
  allAssessments: [],
  error: null,
};

const studySlice = createSlice({
  name: 'study',
  initialState,
  reducers: {
    activateDashboardView: (state) => {
      state.isDashboardView = true;
      state.isAssessmentView = false;
      state.isFinalAssessmentView = false;
      state.isResearchAssessmentView = false;
      state.isEvaluationView = false;
    },
    activateAssessmentsView: (state) => {
      state.isDashboardView = false;
      state.isAssessmentView = true;
      state.isFinalAssessmentView = false;
      state.isResearchAssessmentView = false;
      state.isEvaluationView = false;
    },
    activateFinalAssessmentsView: (state) => {
      state.isDashboardView = false;
      state.isAssessmentView = false;
      state.isFinalAssessmentView = true;
      state.isResearchAssessmentView = false;
      state.isEvaluationView = false;
    },
    activateResearchAssessmentView: (state) => {
      state.isDashboardView = false;
      state.isAssessmentView = false;
      state.isFinalAssessmentView = false;
      state.isResearchAssessmentView = true;
      state.isEvaluationView = false;
    },
    activateEvaluationView: (state) => {
      state.isDashboardView = false;
      state.isAssessmentView = false;
      state.isFinalAssessmentView = false;
      state.isResearchAssessmentView = false;
      state.isEvaluationView = true;
    },
    setActivePatientStudy: (state, { payload }) => {
      state.activePatientStudy = payload;
    },
    updateStudyAssessments: (state, { payload }) => {
      state.studyAssessments = payload;
    },
    updateAllAssessments: (state, { payload }) => {
      state.allAssessments = payload;
    },
    updateError: (state, { payload }) => {
      state.error = payload;
    },
    clearState: () => {
      return initialState;
    },
  },
});

export const {
  activateDashboardView,
  activateAssessmentsView,
  setActivePatientStudy,
  updateStudyAssessments,
  activateFinalAssessmentsView,
  activateResearchAssessmentView,
  activateEvaluationView,
  updateAllAssessments,
  updateError,
  clearState,
} = studySlice.actions;

export default studySlice.reducer;
