import { createSlice } from '@reduxjs/toolkit';
import { ProviderOptions } from '@appointment/features/appointment/interfaces';
import {
  RoomOption,
  RoomType,
  PreviousRoomOption,
  RoomTicket,
  StageOption,
} from '../interfaces';

const rooms: RoomOption[] = [];
const stages: StageOption[] = [];
const checkInProviders: ProviderOptions[] = [];
const selectedRoom = {} as RoomOption;
const previousRoom = {} as PreviousRoomOption;
const selectedStage = {} as StageOption;
const roomType: RoomType[] = [];
const roomTicket: RoomTicket[] = [];
const ticketId: number | null = null;
const initialState = {
  rooms,
  stages,
  checkInProviders,
  selectedRoom,
  previousRoom,
  selectedStage,
  roomType,
  roomTicket,
  ticketId,
};
const patientFlowTicket = createSlice({
  name: 'patientFlowTicket',
  initialState,
  reducers: {
    availableRooms: (state, { payload }) => {
      state.rooms = payload;
    },
    availableStages: (state, { payload }) => {
      state.stages = payload;
    },
    availableProviders: (state, { payload }) => {
      state.checkInProviders = payload;
    },
    updateSelectedRoom: (state, { payload }) => {
      state.selectedRoom = payload;
    },
    updatePreviousRoom: (state, { payload }) => {
      state.previousRoom = payload;
    },
    updateSelectedStage: (state, { payload }) => {
      state.selectedStage = payload;
    },
    updateRoomType: (state, { payload }) => {
      state.roomType = payload;
    },
    addRoomTypeBySocket: (state, { payload }) => {
      state.roomType = [...state.roomType, payload];
    },
    updateRoomTypeBySocket: (state, { payload }) => {
      state.roomType = state.roomType.map((eachRoom) => {
        if (
          eachRoom.id === payload.id &&
          eachRoom.create_date === payload.create_date // Since the socket is not specified to a particular server environment, similar id is not enough for rooms. Create Date add to the uniqueness
        ) {
          eachRoom = payload;
        }
        return eachRoom;
      });
    },
    removeRoomTypeBySocket: (state, { payload }) => {
      state.roomType = state.roomType.filter((eachRoom) => {
        return eachRoom.id !== payload.id;
      });
    },
    updateRoomTicket: (state, { payload }) => {
      state.roomTicket = payload;
    },
    addRoomTicketBySocket: (state, { payload }) => {
      state.roomTicket = [payload, ...state.roomTicket];
    },
    updateRoomTicketBySocket: (state, { payload }) => {
      state.roomTicket = state.roomTicket.map((eachRoom) => {
        // appointment code is unique for each ticket
        if (eachRoom.appointment_code === payload.appointment_code) {
          eachRoom = payload;
        }
        return eachRoom;
      });
    },
    removeRoomTicketBySocket: (state, { payload }) => {
      state.roomTicket = state.roomTicket.filter((eachTicket) => {
        return eachTicket.id !== payload.id;
      });
    },

    updateTicketId: (state, { payload }) => {
      state.ticketId = payload;
    },
    updateRoomTicketByDragging: (state, { payload }) => {
      const ticket = state.roomTicket.find(
        (eachTicket) => eachTicket.id === payload.ticketId,
      );

      const previousRoom = state.roomType.find(
        (eachRoom) => eachRoom.id === payload.previousRoomId,
      );

      const room = state.roomType.find(
        (eachRoom) => eachRoom.id === payload.destinationRoomId,
      );

      const canBeOccupied = ['exam_room', 'dental', 'opthalmology', 'lab'];
      const canBeDirty = ['exam_room', 'dental', 'opthalmology'];

      if (canBeDirty.includes(previousRoom?.room_type as string)) {
        state.roomType = state.roomType.map((eachRoom) => {
          if (eachRoom.id === previousRoom?.id) {
            eachRoom.state = 'dirty';
          }
          return eachRoom;
        });
      }
      state.roomTicket = state.roomTicket.map((eachTicket) => {
        if (eachTicket.id === ticket?.id) {
          eachTicket.room_id = room?.id as number;
        }
        return eachTicket;
      });
      if (canBeOccupied.includes(room?.room_type as string)) {
        state.roomType = state.roomType.map((eachRoom) => {
          if (eachRoom.id === room?.id) {
            eachRoom.state = 'occupied';
          }
          return eachRoom;
        });
      }

      if (previousRoom?.room_type === 'lab') {
        state.roomType = state.roomType.map((eachRoom) => {
          if (eachRoom.id === previousRoom?.id) {
            eachRoom.state = 'clean';
          }
          return eachRoom;
        });
      }
    },
  },
});

export const {
  availableRooms,
  updateSelectedRoom,
  availableStages,
  updateSelectedStage,
  availableProviders,
  updateRoomType,
  updateRoomTypeBySocket,
  updateRoomTicket,
  addRoomTicketBySocket,
  updateRoomTicketBySocket,
  updatePreviousRoom,
  updateTicketId,
  removeRoomTicketBySocket,
  removeRoomTypeBySocket,
  addRoomTypeBySocket,
  updateRoomTicketByDragging,
} = patientFlowTicket.actions;
export default patientFlowTicket.reducer;
