import { createSlice } from '@reduxjs/toolkit';

export interface ClientInfo {
  id?: number;
  name: string;
  phone?: string;
  patient_ref?: string;
  email?: string;
  dob?: string;
  create_username: string;
}

const initialState: { client: ClientInfo | null } = {
  client: null,
};

const clientSlice = createSlice({
  name: 'client',
  initialState,
  reducers: {
    updateClient: (state, { payload }) => {
      state.client = payload;
    },
  },
});
export const { updateClient } = clientSlice.actions;
export default clientSlice.reducer;
