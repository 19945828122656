import { BaseAPI } from 'src/common/services/baseApi';

export class FeatureSettings extends BaseAPI {
  endpointUrl = '/api/feature-settings';
  noInterception = true;

  async getTotalFeaturesCount() {
    const resp = await this.get();
    return resp.length;
  }
}
