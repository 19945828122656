import { createSlice } from '@reduxjs/toolkit';
import { RenderedAppointments } from '../interfaces';

const myAppointments: RenderedAppointments[] = [];
const initialState = {
  myAppointments,
  createNewPatientToast: false,
  clearSelectedPatient: false,
};

const appointment = createSlice({
  name: 'appointment',
  initialState,
  reducers: {
    addAppointment: (state, { payload }) => {
      state.myAppointments = [...state.myAppointments, payload];
    },
    removeAppointment: (state, { payload }) => {
      state.myAppointments = state.myAppointments.filter((eachAppointment) => {
        return eachAppointment.id !== payload.id;
      });
    },
    updateAppointment: (state, { payload }) => {
      state.myAppointments = state.myAppointments.map((eachAppointment) => {
        if (eachAppointment.id === payload.id) {
          eachAppointment = payload;
        }
        return eachAppointment;
      });
    },
    toggleClearSelectedPatient: (state, { payload }) => {
      state.clearSelectedPatient = payload;
    },
    createPatientFromCalendarToast: (state, { payload }) => {
      state.createNewPatientToast = payload;
    },
  },
});

export const {
  toggleClearSelectedPatient,
  removeAppointment,
  addAppointment,
  updateAppointment,
  createPatientFromCalendarToast,
} = appointment.actions;
export default appointment.reducer;
