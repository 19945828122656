import { createSlice } from '@reduxjs/toolkit';

export interface RenderedAppointments {
  branch_id: number;
  client_id: number;
  code: string;
  id: number;
  provider_id: number;
  provider: {
    name: string;
  };
  providerName: string;
  serviceIcon: string;
  appointmentCode: string;
  patientRef: string;
  service_id: number;
  start_time: string;
  client: {
    name: string;
  };
  service: {
    icon: string;
    name: string;
  };
}

export interface SelectedRenderedAppointments {
  id: number;
  name: string;
  serviceIcon: string;
  time: string;
  clientId: number;
  providerName: string;
  serviceName: string;
  start: Date;
  appointmentCode: string;
}
const myAppointments: RenderedAppointments[] = [];
const initialState = {
  fullScreen: false,
  dashboardSettings: {
    showStatusTime: true,
    showTimeInClinic: true,
    showPatientName: true,
    showCheckout: true,
    showAppointment: true,
    showColumns: true,
  },
  myAppointments,
  dailyAppointmentState: false,
  openTicketTransition: false,
  checkInPatient: false,
};

const patientFlow = createSlice({
  name: 'patientFlow',
  initialState,
  reducers: {
    dailyAppointments: (state, { payload }) => {
      state.myAppointments = payload;
    },
    toggleFullScreen: (state, { payload }) => {
      state.fullScreen = payload;
    },
    toggleShowStatusTime: (state, { payload }) => {
      state.dashboardSettings.showStatusTime = payload;
    },
    toggleTimeInClinic: (state, { payload }) => {
      state.dashboardSettings.showTimeInClinic = payload;
    },
    togglePatientName: (state, { payload }) => {
      state.dashboardSettings.showPatientName = payload;
    },
    toggleCheckout: (state, { payload }) => {
      state.dashboardSettings.showCheckout = payload;
    },
    toggleShowAppointment: (state, { payload }) => {
      state.dashboardSettings.showAppointment = payload;
    },
    toggleLayout: (state, { payload }) => {
      state.dashboardSettings.showColumns = payload;
    },
    toggleDailyAppointmentState: (state, { payload }) => {
      state.dailyAppointmentState = payload;
    },
    toggleOpenTicketTransition: (state, { payload }) => {
      state.openTicketTransition = payload;
    },
    toggleCheckInPatient: (state, { payload }) => {
      state.checkInPatient = payload;
    },
  },
});

export const {
  toggleFullScreen,
  toggleShowStatusTime,
  toggleShowAppointment,
  toggleTimeInClinic,
  toggleCheckout,
  togglePatientName,
  toggleLayout,
  dailyAppointments,
  toggleDailyAppointmentState,
  toggleOpenTicketTransition,
  toggleCheckInPatient,
} = patientFlow.actions;
export default patientFlow.reducer;
