import React, { useEffect } from 'react';
import type { AppProps } from 'next/app';
import { NextPage } from 'next';
import Script from 'next/script';

import { SessionProvider } from 'next-auth/react';
import SSRProvider from 'react-bootstrap/SSRProvider';
import { Hydrate, QueryClient, QueryClientProvider } from 'react-query';
import { PatientSideNavState } from '@ambulatory/features/patient/contexts/PatientSideNavProvider';
import { init as initSentry } from 'src/common/utils/monitoring/sentry';
import Head from 'next/head';

import { AuthProvider } from 'src/common/contexts/auth/AuthProvider';
import { AuthGuard } from 'src/common/contexts/auth/AuthGuard';

import { AppDispatch, wrapper } from 'src/common/store/index';
import { FeatureSettings } from '@settings/features/features/services/featureSettigs';

import '../src/common/styles/eha-bootstrap.scss';
import '../src/common/styles/index.scss';

import { w3cwebsocket as W3CWebSocket } from 'websocket';
import { useDispatch } from 'react-redux';
import {
  removeAppointment,
  addAppointment,
  updateAppointment,
} from '@appointment/features/appointment/store/appointment';
import {
  addRoomTicketBySocket,
  updateRoomTicketBySocket,
  removeRoomTicketBySocket,
  updateRoomTypeBySocket,
  removeRoomTypeBySocket,
  addRoomTypeBySocket,
  updateRoomTicketByDragging,
} from '@patientFlow/features/patient-flow/store/PatientFlowTicketSlice';
import {
  addAppointmentToCalendar,
  removeAppoinmentFromCalendar,
  updateCalendarAppointment,
} from '@appointment/features/appointment/store/CalendarSlice';
import {
  addFollowUpMessage,
  removeFollowUpMessage,
  updateFollowUpMessage,
} from 'src/common/store/MessageSlice';
import { setFeatures } from '@settings/features/features/store/featureSettingsSlice';

initSentry();

export type NextApplicationPage<P = any, IP = P> = NextPage<P, IP> & {
  requireAuth?: boolean;
};

declare global {
  interface Window {
    ws: any;
  }
}

function MyApp(props: AppProps) {
  const {
    Component,
    pageProps: { session, ...pageProps },
  }: { Component: NextApplicationPage; pageProps: any } = props;

  // const { data: session } = useSession()

  // eslint-disable-next-line unused-imports/no-unused-vars, no-unused-vars
  const token = String(session?.accessToken);
  const queryClient = new QueryClient();
  const featureAPI = new FeatureSettings();

  const dispatch = useDispatch<AppDispatch>();

  async function fetchFeatureSettings() {
    const features = await featureAPI.get();
    dispatch(setFeatures(features));
  }

  useEffect(() => {
    fetchFeatureSettings();
  }, []);

  useEffect(() => {
    function connect() {
      const server = `${process.env.NEXT_PUBLIC_REACT_APP_PATIENT_FLOW_SOCKET_URL}`;
      const ws = new W3CWebSocket(server);
      window.ws = ws;

      ws.onopen = () => {
        console.log('Websocket opened!');
      };

      ws.onclose = (e) => {
        console.log(
          'Socket is closed. Reconnect will be attempted in 1 second: ',
          e.reason,
        );
        setTimeout(() => {
          connect();
        }, 1000);
      };

      window.ws.onmessage = async (event: { data: any }) => {
        const data = JSON.parse(event.data as any);

        if (data && data.type === 'remove_appointment') {
          dispatch(removeAppointment(data));
        }

        if (data && data.type === 'remove_appointment_from_calendar') {
          dispatch(removeAppoinmentFromCalendar(data));
        }

        if (data && data.type === 'add_appointment') {
          dispatch(addAppointment(data));
          dispatch(addAppointmentToCalendar(data));
        }

        if (data && data.type === 'update_appointment') {
          dispatch(updateCalendarAppointment(data));
          dispatch(updateAppointment(data));
        }

        if (data && data.type === 'create_ticket') {
          dispatch(addRoomTicketBySocket(data));
        }

        if (data && data.type === 'update_ticket') {
          dispatch(updateRoomTicketBySocket(data));
        }

        if (data && data.type === 'remove_ticket') {
          dispatch(removeRoomTicketBySocket(data));
        }

        if (data && data.type === 'add_room') {
          dispatch(addRoomTypeBySocket(data));
        }

        if (data && data.type === 'update_room') {
          dispatch(updateRoomTypeBySocket(data));
        }

        if (data && data.type === 'remove_room') {
          dispatch(removeRoomTypeBySocket(data));
        }

        if (data && data.type === 'add_follow_up') {
          dispatch(addFollowUpMessage(data));
        }

        if (data && data.type === 'update_follow_up') {
          dispatch(updateFollowUpMessage(data));
        }

        if (data && data.type === 'remove_follow_up') {
          dispatch(removeFollowUpMessage(data));
        }

        if (data && data.type === 'update_ticket_by_dragging') {
          dispatch(updateRoomTicketByDragging(data));
        }
      };

      ws.onerror = (err) => {
        console.log(
          'Socket encountered error: ',
          err.message,
          'Closing socket',
        );
        ws.close();
      };
    }
    connect();
  }, []);

  return (
    <QueryClientProvider client={queryClient} contextSharing={true}>
      <Hydrate state={pageProps.dehydratedState}>
        <SSRProvider>
          <Head>
            <meta
              name="viewport"
              content="initial-scale=1.0, width=device-width, user-scalable=no"
            />
            <link rel="shortcut icon" href="/img/favicon.ico" />
          </Head>
          <Script
            id="form-renderer"
            src="/better-web-components/form-renderer/form-renderer.js"
          />
          <PatientSideNavState>
            <SessionProvider session={pageProps.session}>
              <AuthProvider>
                {/* if requireAuth property is present - protect the page */}
                {Component.requireAuth ? (
                  //protected page
                  <AuthGuard>
                    <Component {...pageProps} />
                  </AuthGuard>
                ) : (
                  // public page

                  <Component {...pageProps} />
                )}
              </AuthProvider>
            </SessionProvider>
          </PatientSideNavState>
        </SSRProvider>
      </Hydrate>
    </QueryClientProvider>
  );
}

export default wrapper.withRedux(MyApp);
