import { IEncounter } from '@ambulatory/features/encounter/interfaces/encounter';
import { IPatientAdmission } from '../interfaces/patientAdmission';
import { createSlice } from '@reduxjs/toolkit';

type AdmissionStateType = {
  isDashboardView: boolean;
  isEncountersView: boolean;
  isProceduresView: boolean;
  patientAdmissionCount: number;
  patientAdmissionList: IPatientAdmission[];
  activePatientAdmission: IPatientAdmission | null;
  admissionEncounters: IEncounter[];
  admissionType: string;
};

const initialState: AdmissionStateType = {
  isDashboardView: true,
  isEncountersView: false,
  isProceduresView: false,
  patientAdmissionCount: 0,
  patientAdmissionList: [],
  activePatientAdmission: null,
  admissionEncounters: [],
  admissionType: '',
};

const admissionSlice = createSlice({
  name: 'admission',
  initialState,
  reducers: {
    activateDashboardView: (state) => {
      state.isDashboardView = true;
      state.isEncountersView = false;
      state.isProceduresView = false;
    },
    activateEncountersView: (state) => {
      state.isDashboardView = false;
      state.isEncountersView = true;
      state.isProceduresView = false;
    },
    activateProceduresView: (state) => {
      state.isDashboardView = false;
      state.isEncountersView = false;
      state.isProceduresView = true;
    },
    updatePatientAdmissionList: (state, { payload }) => {
      state.patientAdmissionList = payload;
    },
    updatePatientAdmissionCount: (state, { payload }) => {
      state.patientAdmissionCount = payload;
    },
    updateAdmissionEncounters: (state, { payload }) => {
      state.admissionEncounters = payload;
    },
    setActivePatientAdmission: (state, { payload }) => {
      state.activePatientAdmission = payload;
    },
    setAdmissionTypeFilter: (state, { payload }) => {
      state.admissionType = payload;
    },
    clearState: () => {
      return initialState;
    },
  },
});

export const {
  activateDashboardView,
  updateAdmissionEncounters,
  activateEncountersView,
  activateProceduresView,
  setActivePatientAdmission,
  updatePatientAdmissionList,
  updatePatientAdmissionCount,
  setAdmissionTypeFilter,
  clearState,
} = admissionSlice.actions;

export default admissionSlice.reducer;
