import axios from 'axios';
import { getSession } from 'next-auth/react';
import qs from 'qs';
import { ISession } from 'src/common/interfaces/session';
import createAuthRefreshInterceptor from 'axios-auth-refresh';

const baseURL = process.env.NEXT_PUBLIC_BETTECARE_HOST;

const axiosInstance: any = axios.create({
  baseURL,
  withCredentials: false,
});

createAuthRefreshInterceptor(
  axiosInstance,
  async (request) => {
    // fetch refresh token
    const session = (await getSession()) as unknown as ISession['data'];
    const accessToken = session?.accessToken;
    // 1a. Clear old helper cookie used in 'authorize.ts' higher order function.
    if (axiosInstance.defaults.headers.setCookie) {
      delete axiosInstance.defaults.headers.setCookie;
    }
    // 2. Set up new access token
    const bearer = `Bearer ${accessToken}`;
    axiosInstance.defaults.headers.Authorization = bearer;

    // 4. Set up access token of the failed request.
    request.response.config.headers.Authorization = bearer;
    // 5. Retry the request with new setup!
    return Promise.resolve();
  },
  {
    statusCodes: [401],
  },
);

// axiosInstance.interceptors.request.use(async (request) => {
//     if (!isAccessTokenAttachedToAxiosDefaults())
//         await setAccessTokenOnRequestAndAsAxiosDefaults(request);
//     return request;
// });

// const isAccessTokenAttachedToAxiosDefaults = () => {
//     const authHeader = axiosInstance.defaults.headers.common['Authorization'];
//     if (authHeader === null || authHeader === undefined || authHeader === '')
//         return false;
//     else return true;
// };

// const setAccessTokenOnRequestAndAsAxiosDefaults = async (
//     request: AxiosRequestConfig
// ) => {
//     const session = await getSession();
//     if (session) {
//         const AuthHeaderValue = `Bearer ${session.accessToken}`;
//         if (!request.headers) request.headers = {};
//         request.headers.Authorization = AuthHeaderValue;
//         /* NOTE - This is to prevent calling getSession() again and again for each request.
//             Because getSession() internally calls api/auth/session, which would be very expensive to do
//             for each request to our backend [Call to this API was consuming around 10% of our bandwidth provided to us by vercel].
//             It will not only lead to increase in costs but also increase time to perform each request as
//             we have to every-time make a remote call to /api/auth/session. */
//         axiosInstance.defaults.headers.common['Authorization'] = AuthHeaderValue;
//     }
// };

export const unsetAccessTokenAttachedToAxiosDefaults = () => {
  delete axiosInstance.defaults.headers.common['Authorization'];
};

export const loginData = qs.stringify({
  grant_type: 'password',
  client_id: 'portal',
  username: process.env.NEXT_PUBLIC_BETTER_CARE_USERNAME,
  password: process.env.NEXT_PUBLIC_BETTER_CARE_PASSWORD,
});

export const setRequestHeaders = (token?: string) => {
  //the primary usecase for this method is unit testing where we need to "pass in" the token
  const _headers = { 'content-type': 'application/json' } as {
    [key: string]: string;
  };
  if (token) {
    _headers['Authorization'] = `Bearer ${token}`;
  }
  return _headers;
};

const memo = (callback: any) => {
  // We will save the key-value pairs in the following variable. It will be our cache storage
  const cache = new Map();
  return (...args: any) => {
    // The key will be used to identify the different arguments combination. Same arguments means same key
    const key = JSON.stringify(args);
    // If the cache storage has the key we are looking for, return the previously stored value
    if (cache.has(key)) return cache.get(key);
    // If the key is new, call the function (in your case axios.get)
    const value = callback(...args);
    // And save the new key-value pair to the cache
    cache.set(key, value);
    return value;
  };
};

export const memoizedAxiosGet = memo(axiosInstance.get) as any;
export const memoizedAxiosPost = memo(axiosInstance.post);
export default axiosInstance;
