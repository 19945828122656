import { createSlice } from '@reduxjs/toolkit';
import {
  IReviewComposition,
  IChartReviewReportComposition,
  IPrescriptionAuditComposition,
} from '@qapp/features/chart-audits/interfaces/chartAudits';
import { REVIEW_SUMMARY } from 'src/common/constants/constants';

interface IChartReview {
  reviewTaskItem: (IReviewComposition | IPrescriptionAuditComposition)[];
  reviewTaskItems: (IReviewComposition | IPrescriptionAuditComposition)[];
  reviewTaskItemsIds: {
    composition_id: string;
    encounter_id: { value: string };
  }[];
  activeReviewReport: IChartReviewReportComposition | null;
  selectedTab: string;
  refetchCurrentReviews: boolean;
}

export type ReviewInfoDetails = {
  assessment: string;
  assessmentLabel?: string;
  rating: {
    value: string;
    label: string;
  };
  comment: string;
};

export type NurseReviewInfo = {
  vitalSigns: ReviewInfoDetails;
  currentMedications: ReviewInfoDetails;
  fallRisk: ReviewInfoDetails;
  painAssessment: ReviewInfoDetails;
  painReassessment: ReviewInfoDetails;
  nutritionalAssessment: ReviewInfoDetails;
  procedures: ReviewInfoDetails;
  mentalHealth: ReviewInfoDetails;
};

export type DoctorReviewInfo = {
  accurateDiagnosis: ReviewInfoDetails;
  useOfLabResults: ReviewInfoDetails;
  noteOfTreatment: ReviewInfoDetails;
  documentedReassessment: ReviewInfoDetails;
  documentationAccuracy: ReviewInfoDetails;
  abbreviations: ReviewInfoDetails;
  chartCompleteness: ReviewInfoDetails;
  clinicalEvaluationComplete: ReviewInfoDetails;
};

export type PharmacistReviewInfo = {
  currentMedications: ReviewInfoDetails;
  currentAllergies: ReviewInfoDetails;
  correctRouteOfAdministration: ReviewInfoDetails;
  drugDosage: ReviewInfoDetails;
  frequencyOfAdministration: ReviewInfoDetails;
  drugDuration: ReviewInfoDetails;
  prescriptionIsMissingDiagnosis: ReviewInfoDetails;
  prescriptionMatchesIndication: ReviewInfoDetails;
  therapeuticDuplication: ReviewInfoDetails;
  drugInteraction: ReviewInfoDetails;
  completionOfTheDispenseProcess: ReviewInfoDetails;
  medicationErrorStatus: ReviewInfoDetails;
};

// 1 => To Do
// 2 => In Progress
// 3 => Completed

const initialState: IChartReview = {
  reviewTaskItem: [],
  reviewTaskItems: [],
  reviewTaskItemsIds: [],
  activeReviewReport: null,
  selectedTab: REVIEW_SUMMARY,
  refetchCurrentReviews: false,
};

const chartReviewSlice = createSlice({
  name: 'chartReview',
  initialState,
  reducers: {
    setSelectedTab: (state, { payload }) => {
      state.selectedTab = payload;
    },
    setReviewTaskItem: (state, { payload }) => {
      state.reviewTaskItem = payload;
    },
    setReviewTaskItems: (state, { payload }) => {
      state.reviewTaskItems = payload;
    },
    setReviewTaskItemsIds: (state, { payload }) => {
      state.reviewTaskItemsIds = payload;
    },
    setActiveReviewReport: (state, { payload }) => {
      state.activeReviewReport = payload;
    },
    resetReviewTaskItems: (state) => {
      state.reviewTaskItems = [];
    },
    toggleRefetchCurrentReviews: (state, { payload }) => {
      state.refetchCurrentReviews = payload;
    },
  },
});

export const {
  setSelectedTab,
  setReviewTaskItem,
  setReviewTaskItems,
  setReviewTaskItemsIds,
  setActiveReviewReport,
  resetReviewTaskItems,
  toggleRefetchCurrentReviews,
} = chartReviewSlice.actions;

export default chartReviewSlice.reducer;
